<template>
	<div class="layindex">
		<img class="logoname" src="@/assets/logoname.png" alt="">
		<div class="content-wrap">
			<div class="content">
				<div class="avatar">
					<van-image round width="100%" height="100%" :src="inviteInfo.avatar" fit="cover" />
				</div>
				<div class="desc">
					<span class="nickname">{{ inviteInfo.nickname }}</span>喊你一起来打球~
				</div>
			</div>
		</div>
		<img class="ball" src="@/assets/ball-logo.png" alt="">
		<div class="btnbox" @click="getCode">立即注册</div>
	</div>
</template>

<script>
import config from '@/utils/config.js'
import { Dialog } from 'cube-ui'
import ballLogo from '@/assets/ball-logo.png'
export default {
	name: 'Invite',
	created () {
		this.getSystemInfo()
		this.getIsCode()

	},
	mounted () {
		if (this.$route.query && this.$route.query.invite_id) {
			this.invite_id = this.$route.query.invite_id
		}
		this.getInviteInfo(this.invite_id)
	},
	data () {
		return {
			invite_id: 0,
			inviteInfo: { nickname: '曼巴篮球', avatar: ballLogo }
		}
	},
	methods: {
		getInviteInfo (invite_id) {
			this.$http.getInviteInfo({
				invite_id: invite_id
			}).then(res => {
				if (res.code == 200) {
					if (Object.keys(res.data).length > 0) {
						this.inviteInfo = res.data;
					}
				}
			}).catch(err => {
				console.log(err);
			});
		},
		getSystemInfo () {
			this.$http.getSystemInfo().then(res => {
				if (res.code == 200) {
					this.systemInfo = res.data;
					window.localStorage.setItem('systemTelephone', res.data.phone)
				}
			}).catch(err => {
				console.log(err);
			});
		},
		goindex () {
			if (this.$store.state.bindMobile == 0) {
				Dialog.$create({
					type: 'alert',
					title: '提示',
					content: "请绑定您的手机号",
					//icon: 'cubeic-alert',
					confirmBtn: {
						text: '去绑定',
						active: true,
						disabled: false,
						href: 'javascript:;'
					},
					onConfirm: () => {
						console.log('bind')
						this.$router.push({
							path: '/bind-mobile'
						})
					},
				}).show()
			} else {
				window.location.href = '/#/botnav/index'
			}
		},
		GetUrlParam (name) {
			var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
			var r = window.location.search.substr(1).match(reg);
			console.log(r, 'param', this.$route.query);
			if (r != null) return unescape(r[2]);
			return null;
		},
		getCode () {
			const code = this.GetUrlParam("code");
			const state = this.GetUrlParam("state");
			const local = window.location.href;
			if (code == null || code === "") {
				window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + config.appid + "&redirect_uri=" + encodeURIComponent(local) + "&response_type=code&scope=snsapi_userinfo&state=invite-" + (this.invite_id) + "#wechat_redirect";
			} else {
				this.getToken(code, state);
			}
		},
		getIsCode () {
			const code = this.GetUrlParam("code");
			const state = this.GetUrlParam("state");
			if (code == null || code === "") {
				return false
			} else {
				this.getToken(code, state);
			}
		},
		getToken (code, state) {
			console.log(code)
			let param = {
				'code': code
			}
			if (state.split("-").length == 2) {
				param.invite_id = state.split("-")[1]
			}
			this.$http.getLogin(param).then(res => {
				if (res.code == 200) {
					this.$store.commit('settoken', res.data.token)
					localStorage.setItem('token', res.data.token)
					localStorage.setItem('level', res.data.level)
					localStorage.setItem('isSupper', res.data.is_supper)
					this.$store.commit('setbindMobile', res.data.bindMobile)
					this.$store.commit('setUserInfo', res.data.userInfo)
					this.goindex()
				}
			}).catch(err => {
				console.log(err);
			});
		},
	},
}
</script>

<style scoped lang="less">
.layindex {
	width: 100%;
	height: 100%;
	position: relative;
	background: url(../assets/indexbg.png) no-repeat center;
	background-size: cover;
	overflow: hidden;
	z-index: 9;

	.logoname {
		width: 158px;
		height: 35px;
		margin: 37px auto 0;
		display: block;
	}

	.content-wrap {
		background: url(../assets/invite-bg.png) no-repeat center;
		background-size: cover;
		width: 328px;
		height: 177px;
		margin: 26px auto 26px;

		.content {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 70px 24px 47px 32px;

			.avatar {
				width: 61px;
				height: 61px;
			}

			.desc {
				margin-left: 18px;
				line-height: 14px;
				font-size: 14px;
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 500;
				color: #214087;

				.nickname {
					line-height: 18px;
					font-size: 18px;
					margin-right: 4px;
				}
			}
		}

	}

	.ball {
		width: 114px;
		height: 114px;
		display: block;
		margin: 0 auto;
	}

	.btnbox {
		width: 184px;
		height: 48px;
		text-align: center;
		line-height: 48px;
		background: #F9A709;
		box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.3);
		opacity: 0.9;
		border-radius: 48px;
		font-weight: 400;
		color: #FFFFFF;
		font-size: 20px;
		margin: 92px auto 0;
		display: block;
		letter-spacing: 2px;
	}
}
</style>
